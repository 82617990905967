import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'animate.css';
import 'element-plus/theme-chalk/dark/css-vars.css'; // 引入全局暗黑主题

const app = createApp(App)
app.use(ElementPlus)
app.mount('#app')
