<template>
  <div class="body">

    <div class="layer1"></div>
    <div class="layer2"></div>
    <div class="layer3"></div>


    <div class="box">
      <div class="header">
        <div class="welcome">
          欢迎使用环保工具箱
        </div>

        <input type="text" v-model="searchQuery" class="search-input" ref="inputField" placeholder="输入搜索内容..."
          @input="search" />
        <button class="clear-button" @click="clearInput" v-if="showButton">✖</button>
        <button class="search-button">搜索</button>
      </div>

      <div class="container">
        <div class="card" v-for="item in filteredItems" :key="item" @click="calcDialog(item)">
          <div class="inner">{{ item }}</div>
        </div>
      </div>
    </div>

    <div class="footer">
      辽ICP备2022006040号-1
    </div>

    <RepeatDialog v-if="repeatVisible" @addEvent="handle"/>
    <TwentyFourDialog v-if="twentyFourVisible" @addEvent="handle"/>
    <ResolutionDialog v-if="resolution" @addEvent="handle"/>


  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

import RepeatDialog from '@/comonpents/RepeatDialog'
import TwentyFourDialog from '@/comonpents/TwentyFourDialog'
import ResolutionDialog from '@/comonpents/ResolutionDialog'

const searchQuery = ref('');
const showButton = ref(false);
const repeatVisible = ref(false);
const twentyFourVisible = ref(false);
const resolution = ref(false)

const map = new Map();
map.set('重复性计算',repeatVisible);
map.set('24小时漂移',twentyFourVisible)
map.set('VOC分离度',resolution)


const items = ref(["重复性计算","24小时漂移","VOC分离度"]);


onMounted(() => {
  const container = document.querySelector(".container");
  const cards = document.querySelectorAll(".card");
  container.onmousemove = e => {
    for (const card of cards) {
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left - rect.width / 2;
      const y = e.clientY - rect.top - rect.height / 2;
      card.style.setProperty('--x', `${x}px`)
      card.style.setProperty('--y', `${y}px`)
    }
  }

});

const search = () => {
  showButton.value = !!searchQuery.value;
}
const clearInput = () => {
  searchQuery.value = '';
  showButton.value = false;
}


// 模糊匹配函数
const isFuzzyMatch = (str, query) => {
  let strIndex = 0;
  let queryIndex = 0;

  while (strIndex < str.length && queryIndex < query.length) {
    if (str[strIndex] === query[queryIndex]) {
      queryIndex++;
    }
    strIndex++;
  }

  return queryIndex === query.length; // 如果查询字符串的所有字符都被找到
};

// 计算属性：过滤项
const filteredItems = computed(() => {
  return items.value.filter(item =>
    isFuzzyMatch(item.toLowerCase(), searchQuery.value.toLowerCase())
  );
});


const calcDialog = (item) => {
  console.log(item)
  map.get(item).value = true;
}

const handle = value => {
  console.log(value)
  map.get(value).value = false;
}


</script>

<style scoped lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background-color: #000;
}

.layer1 {
  width: 100vw;
  height: 100vh;
  position: fixed;
}


.box {
  width: 100%;
  min-height: calc(100vh - 141px);
}


.clear-button {
  position: absolute;
  left: 770px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  font-weight: 100;
  /* 默认隐藏 */
}

.clear-button.visible {
  display: block;
  /* 显示按钮 */
}

.search-input {
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  background-color: #1e1e1e;
  color: #ffffff;
  width: 400px;
  height: 60px;
  outline: none;
  box-sizing: border-box;
}

.search-button {
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #6200ee;
  color: #ffffff;
  cursor: pointer;
  height: 60px;
  width: 100px;
  box-sizing: border-box;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #3700b3;
}


.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@function getShadows($n) {
  $shadows: '#{random(100)}vw #{random(100)}vh #fff';

  @for $i from 1 through $n {
    $shadows: '#{$shadows},#{random(100)}vw #{random(100)}vh #fff';
  }

  @return unquote($shadows);
}

$duration: 400s;
$count: 1000;

@for $i from 1 through 3 {
  $duration: calc($duration / 2);
  $count: calc($count / 2);

  .layer#{$i} {
    $size: #{$i}px;
    position: fixed;
    width: $size;
    height: $size;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-shadow: getShadows($count);
    animation: moveUp $duration linear infinite;

    &::after {
      content: '';
      position: fixed;
      left: 0;
      top: 100vh;
      width: $size;
      height: $size;
      border-radius: inherit;
      box-shadow: inherit;
    }
  }
}


@keyframes moveUp {
  100% {
    transform: translateY(-100vh);
  }
}

.container {
  width: 80%;
  display: grid;
  margin: 0 auto;
  color: #f0f0f0;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  text-align: center;
}

.header {
  width: 80%;
  height: 100px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.welcome {
  color: #fff;
  width: 400px;
  font-size: 24px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  ;
}

.card {
  aspect-ratio: 4/3;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, .3);
  position: relative;
  overflow: hidden;
  transition: transform .3s linear;
}

.card:hover {
  transform: translateY(-5px);
}

.card::before {
  content: "";
  position: absolute;
  inset: 0;
  background: radial-gradient(closest-side circle, #fff, transparent);
  transform: translate(var(--x, -1000px), var(--y, -1000px));
}

.inner {
  position: absolute;
  inset: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border-radius: inherit;
}

.footer {
  margin-top: 40px;
  height: 100px;
  color: #999;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #999;
  font-size: 12px;
}

::v-deep .el-dialog {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
  font-size: 24px;
}

::v-deep .el-dialog__title {
  color: #E5EAF3;
}


</style>
