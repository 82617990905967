<template>
  <div class="calc-page">
    <div class="svg">
      <svg width="200" :height="200">
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow dx="2" dy="5" stdDeviation="5" flood-color="rgba(0, 0, 0, 0.5)"/>
          </filter>
        </defs>
        <path d="M 199.25 87.81 A 100 100 0 0 0 113.92 0.97 L 100 100 Z" fill="#00adb5" filter="url(#shadow)"></path>
      </svg>
    </div>
    <el-dialog v-model="visiable" :close-on-click-modal="false" @close="closeDialog">
      <div class="dialog-class">
        <el-card style="width: 600px">
          <template #header>
            <div class="card-header">
              <h1>VOC分离度</h1>
            </div>
          </template>
          <div>
            <el-form ref="ruleFormRef" style="max-width: 600px" :model="ruleForm" status-icon :rules="rules"
                     label-width="auto" class="demo-ruleForm">
              <el-form-item label="前一峰保留时间" prop="num1">
                <el-input v-model.number="ruleForm.num1" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="后一峰保留时间" prop="num2">
                <el-input v-model.number="ruleForm.num2" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="相邻两峰前一峰宽度" prop="num3">
                <el-input v-model.number="ruleForm.num3" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="相邻两峰后一峰宽度" prop="num4">
                <el-input v-model.number="ruleForm.num4" autocomplete="off"/>
              </el-form-item>

              <el-form-item>
                <span style="color: red">
                   要求环戊烷和异戊烷的分离度、2.3-二甲基戊烧和 2-甲基已烷的分离度及邻-二甲苯和苯乙烯的分离度达到1.0以上。
                </span>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submitForm(ruleFormRef)">
                  提交
                </el-button>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <template #footer>
            {{ result }}
          </template>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, ref, defineEmits} from 'vue';

const visiable = ref(true)

const ruleFormRef = ref(null)

const rules = reactive({
  num1: [{required: true, message: "请输入前一峰保留时间", trigger: 'blur'}],
  num2: [{required: true, message: "请输入后一峰保留时间", trigger: 'blur'}],
  num3: [{required: true, message: "请输入相邻两峰前一峰宽度", trigger: 'blur'}],
  num4: [{required: true, message: "请输入相邻两峰后一峰宽度", trigger: 'blur'}],
})

const ruleForm = reactive({
  num1: '',
  num2: '',
  num3: '',
  num4: '',
})

const submitForm = (formEl) => {
  console.log(formEl)
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      result.value = "当前分离度是："+(((ruleForm.num1 - ruleForm.num2) * 2) / (ruleForm.num3 + ruleForm.num4)).toFixed(2);
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  result.value = ''
}

const result = ref('')

const emits = defineEmits(['addEvent']) //addEvent是父元素里面的一个函数方法，通过这个方法传参

const closeDialog = () => {
  emits('addEvent', "VOC分离度")
}

</script>

<style scoped>

</style>