<template>
  <div class="calc-page">

    <div class="svg">
      <svg width="200" :height="200">
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow dx="2" dy="5" stdDeviation="5" flood-color="rgba(0, 0, 0, 0.5)"/>
          </filter>
        </defs>
        <path d="M 199.25 87.81 A 100 100 0 0 0 113.92 0.97 L 100 100 Z" fill="#00adb5" filter="url(#shadow)"></path>
      </svg>
    </div>
    <el-dialog v-model="visiable" :close-on-click-modal="false" @close="closeDialog">
      <div class="dialog-class">
        <el-card style="width: 600px">
          <template #header>
            <div class="card-header">
              <h1>重复性计算</h1>
            </div>
          </template>
          <div>
            <el-form ref="ruleFormRef" style="max-width: 600px" :model="ruleForm" status-icon :rules="rules"
                     label-width="auto" class="demo-ruleForm">
              <el-form-item label="请输入一组数据" prop="num1">
                <el-input v-model.number="ruleForm.num1" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入二组数据" prop="num2">
                <el-input v-model.number="ruleForm.num2" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入三组数据" prop="num3">
                <el-input v-model.number="ruleForm.num3" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入四组数据" prop="num4">
                <el-input v-model.number="ruleForm.num4" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入五组数据" prop="num5">
                <el-input v-model.number="ruleForm.num5" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入六组数据" prop="num6">
                <el-input v-model.number="ruleForm.num6" autocomplete="off"/>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="submitForm(ruleFormRef)">
                  提交
                </el-button>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <template #footer>
            {{ repeateResult }}
          </template>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>

import {reactive, ref, defineEmits} from 'vue';

const visiable = ref(true)

const rules = reactive({
  num1: [{required: true, message: "请输入第一组数据", trigger: 'blur'}],
  num2: [{required: true, message: "请输入第二组数据", trigger: 'blur'}],
  num3: [{required: true, message: "请输入第三组数据", trigger: 'blur'}],
  num4: [{required: true, message: "请输入第四组数据", trigger: 'blur'}],
  num5: [{required: true, message: "请输入第五组数据", trigger: 'blur'}],
  num6: [{required: true, message: "请输入第六组数据", trigger: 'blur'}],
})

const ruleFormRef = ref(null)
const ruleForm = reactive({
  num1: '',
  num2: '',
  num3: '',
  num4: '',
  num5: '',
  num6: '',
})
const repeateResult = ref('');

const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      const avg = ruleForm.num1 + ruleForm.num2 + ruleForm.num3 + ruleForm.num4 + ruleForm.num5 + ruleForm.num6
      const temp = Math.sqrt((Math.pow((ruleForm.num1 - avg), 2) + Math.pow((ruleForm.num2 - avg), 2) + Math.pow((ruleForm.num3 - avg), 2) + Math.pow((ruleForm.num4 - avg), 2) + Math.pow((ruleForm.num5 - avg), 2) + Math.pow((ruleForm.num6 - avg), 2)) / 5) / avg
      const result = temp.toFixed(4)
      if (result <= 0.1) {
        repeateResult.value = "在合规返回之内结果是" + (result * 100).toFixed(2) + "%"
      } else {
        repeateResult.value = "不在合规返回之内结果是" + (result * 100).toFixed(2) + "%"
      }
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  repeateResult.value = ''
}


const emits = defineEmits(['addEvent']) //addEvent是父元素里面的一个函数方法，通过这个方法传参

const closeDialog = () => {
  emits('addEvent', "重复性计算")
}


</script>

<style>


</style>