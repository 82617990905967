<template>
  <div class="calc-page">

    <div class="svg">
      <svg width="200" :height="200" >
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow dx="2" dy="5" stdDeviation="5" flood-color="rgba(0, 0, 0, 0.5)" />
          </filter>
        </defs>
        <path d="M 199.25 87.81 A 100 100 0 0 0 113.92 0.97 L 100 100 Z" fill="#00adb5" filter="url(#shadow)"></path>
      </svg>
    </div>

    <el-dialog v-model="visiable"  @close="closeDialog" :close-on-click-modal="false">
      <div class="dialog-class">
        <el-card style="width: 600px">
          <template #header>
            <div class="card-header">
              <h1 class="animate__bounceInDown">24小时漂移</h1>
            </div>
          </template>
          <div>
            <el-form ref="ruleFormRef" style="max-width: 600px" :model="ruleForm" status-icon :rules="rules"
                     label-width="auto" class="demo-ruleForm">
              <el-form-item label="请输入1组数据" prop="num1">
                <el-input v-model.number="ruleForm.num1" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入2组数据" prop="num2">
                <el-input v-model.number="ruleForm.num2" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入3组数据" prop="num3">
                <el-input v-model.number="ruleForm.num3" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入4组数据" prop="num4">
                <el-input v-model.number="ruleForm.num4" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入5组数据" prop="num5">
                <el-input v-model.number="ruleForm.num5" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入6组数据" prop="num6">
                <el-input v-model.number="ruleForm.num6" autocomplete="off"/>
              </el-form-item>

              <el-form-item label="请输入7组数据" prop="num7">
                <el-input v-model.number="ruleForm.num7" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入8组数据" prop="num8">
                <el-input v-model.number="ruleForm.num8" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入9组数据" prop="num9">
                <el-input v-model.number="ruleForm.num9" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入10组数据" prop="num10">
                <el-input v-model.number="ruleForm.num10" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入11组数据" prop="num11">
                <el-input v-model.number="ruleForm.num11" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入12组数据" prop="num12">
                <el-input v-model.number="ruleForm.num12" autocomplete="off"/>
              </el-form-item>

              <el-form-item label="请输入13组数据" prop="num13">
                <el-input v-model.number="ruleForm.num13" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入14组数据" prop="num14">
                <el-input v-model.number="ruleForm.num14" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入15组数据" prop="num15">
                <el-input v-model.number="ruleForm.num15" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入16组数据" prop="num16">
                <el-input v-model.number="ruleForm.num16" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入17组数据" prop="num17">
                <el-input v-model.number="ruleForm.num17" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入18组数据" prop="num18">
                <el-input v-model.number="ruleForm.num18" autocomplete="off"/>
              </el-form-item>

              <el-form-item label="请输入19组数据" prop="num19">
                <el-input v-model.number="ruleForm.num19" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入20组数据" prop="num20">
                <el-input v-model.number="ruleForm.num20" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入21组数据" prop="num21">
                <el-input v-model.number="ruleForm.num21" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入22组数据" prop="num22">
                <el-input v-model.number="ruleForm.num22" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入23组数据" prop="num23">
                <el-input v-model.number="ruleForm.num23" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入24组数据" prop="num24">
                <el-input v-model.number="ruleForm.num24" autocomplete="off"/>
              </el-form-item>
              <el-form-item label="请输入量程数据" prop="num25">
                <el-input v-model.number="ruleForm.num25" autocomplete="off"/>
              </el-form-item>
              <el-form-item>
                <el-select
                    v-model="value"
                    placeholder="Select"
                    size="large"
                    style="width:100%"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm(ruleFormRef)">
                  提交
                </el-button>
                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <template #footer>
            {{ twentyFourResult }}
          </template>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>

import {reactive, ref, defineEmits} from 'vue';
import 'animate.css'
const visiable = ref(true)

const rules = reactive({
  num1: [{required: true, message: "请输入第1组数据", trigger: 'blur'}],
  num2: [{required: true, message: "请输入第2组数据", trigger: 'blur'}],
  num3: [{required: true, message: "请输入第3组数据", trigger: 'blur'}],
  num4: [{required: true, message: "请输入第4组数据", trigger: 'blur'}],
  num5: [{required: true, message: "请输入第5组数据", trigger: 'blur'}],
  num6: [{required: true, message: "请输入第6组数据", trigger: 'blur'}],

  num7: [{required: true, message: "请输入第7组数据", trigger: 'blur'}],
  num8: [{required: true, message: "请输入第8组数据", trigger: 'blur'}],
  num9: [{required: true, message: "请输入第9组数据", trigger: 'blur'}],
  num10: [{required: true, message: "请输入第10组数据", trigger: 'blur'}],
  num11: [{required: true, message: "请输入第11组数据", trigger: 'blur'}],
  num12: [{required: true, message: "请输入第12组数据", trigger: 'blur'}],

  num13: [{required: true, message: "请输入第13组数据", trigger: 'blur'}],
  num14: [{required: true, message: "请输入第14组数据", trigger: 'blur'}],
  num15: [{required: true, message: "请输入第15组数据", trigger: 'blur'}],
  num16: [{required: true, message: "请输入第16组数据", trigger: 'blur'}],
  num17: [{required: true, message: "请输入第17组数据", trigger: 'blur'}],
  num18: [{required: true, message: "请输入第18组数据", trigger: 'blur'}],

  num19: [{required: true, message: "请输入第19组数据", trigger: 'blur'}],
  num20: [{required: true, message: "请输入第20组数据", trigger: 'blur'}],
  num21: [{required: true, message: "请输入第21组数据", trigger: 'blur'}],
  num22: [{required: true, message: "请输入第22组数据", trigger: 'blur'}],
  num23: [{required: true, message: "请输入第23组数据", trigger: 'blur'}],
  num24: [{required: true, message: "请输入第24组数据", trigger: 'blur'}],
  num25: [{required: true, message: "请输入量程数据", trigger: 'blur'}],
})
const value = ref('1')
const options = [
  {
    value: '1',
    label: '20%',
  },
  {
    value: '2',
    label: '80%',
  }
]

const ruleFormRef = ref(null)
const ruleForm = reactive({
  num1: '',
  num2: '',
  num3: '',
  num4: '',
  num5: '',
  num6: '',
  num7: '',
  num8: '',
  num9: '',
  num10: '',
  num11: '',
  num12: '',
  num13: '',
  num14: '',
  num15: '',
  num16: '',
  num17: '',
  num18: '',
  num19: '',
  num20: '',
  num21: '',
  num22: '',
  num23: '',
  num24: '',
  num25: '',
})
const twentyFourResult = ref('');

const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      const avg = (ruleForm.num1 + ruleForm.num2 + ruleForm.num3) / 3
      const max = Math.max(Math.abs(ruleForm.num3 - avg), Math.abs(ruleForm.num4 - avg), Math.abs(ruleForm.num5 - avg), Math.abs(ruleForm.num6 - avg), Math.abs(ruleForm.num7 - avg), Math.abs(ruleForm.num8 - avg), Math.abs(ruleForm.num9 - avg), Math.abs(ruleForm.num10 - avg), Math.abs(ruleForm.num11 - avg), Math.abs(ruleForm.num12 - avg), Math.abs(ruleForm.num13 - avg), Math.abs(ruleForm.num14 - avg), Math.abs(ruleForm.num15 - avg), Math.abs(ruleForm.num16 - avg), Math.abs(ruleForm.num17 - avg), Math.abs(ruleForm.num18 - avg), Math.abs(ruleForm.num19 - avg), Math.abs(ruleForm.num20 - avg), Math.abs(ruleForm.num21 - avg), Math.abs(ruleForm.num22 - avg), Math.abs(ruleForm.num23 - avg), Math.abs(ruleForm.num24 - avg))
      const percent = (max / ruleForm.num25).toFixed(4);
      let percentResult = (percent * 100).toFixed(2) + '% :'
      if (value.value === '1') {
        if (percent >= -0.05 && percent <= 0.05) {
          percentResult += '合格'
        } else {
          percentResult += '不合格'
        }
      } else {
        if (percent >= -0.1 && percent <= 0.1) {
          percentResult += '合格'
        } else {
          percentResult += '不合格'
        }
      }
      twentyFourResult.value = percentResult;
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  twentyFourResult.value = ''
}


const emits = defineEmits(['addEvent']) //addEvent是父元素里面的一个函数方法，通过这个方法传参

const closeDialog = () => {
  emits('addEvent', "24小时漂移")
}

</script>

<style>

.calc-page{
  position: absolute;
  width: 100vw;
  height: 100vh;
  border-top-right-radius: 100px;
  overflow-x: hidden;
  background: #999;


}
.dialog-class{
  display: flex;
  justify-content: center;
}

.svg{
  position: absolute;
  right: 0;
  top: 0;
}



.card-header{
  opacity: 0; /* 初始透明度 */
  transform: translateY(-150px); /* 初始位置在上方 */
  animation: flyIn 1.3s forwards 1s; /* 动画 */
}

.calc-page{
  position: absolute; /* 绝对定位 */
  left: 0; /* 左侧对齐 */
  width: 100vw; /* 宽度为视口宽度 */
  height: 100vh; /* 高度为视口高度 */
  transform: scaleX(0); /* 初始缩放为0 */
  transform-origin: left; /* 设置缩放原点为左下角 */
  animation: flipping 1s forwards; /* 动画 */
}



@keyframes flipping {
  to {
    transform: scaleX(1); /* 最终缩放为1 */
  }
}

@keyframes flyIn {
  0% {
    opacity: 0; /* 最终透明度 */
    transform: translateY(0); /* 最终位置 */
  }

  80% {
    opacity: .8; /* 最终透明度 */
    transform: translateY(10px); /* 最终位置 */
  }

  100% {
    opacity: 1; /* 最终透明度 */
    transform: translateY(0); /* 最终位置 */
  }
}

</style>